<template>
  <base-layout>
    <header-bar></header-bar>
    <ion-content has-bouncing="false" class="has-header">
      <ion-refresher slot="fixed" @ionRefresh="updateNews($event)">
        <ion-refresher-content
            pulling-text="Pull to refresh...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-item v-if="fetchActive" :style="{'text-align':'center'}">
        <ion-spinner></ion-spinner>
      </ion-item>
      <div v-if="posts && posts.length > 0">
        <div  @click="collapse(post.id)" v-for="post in posts " :key="post.id" class="list card shadow"
                  style="display:flex; flex-direction:row; margin:10px 10px 20px 10px"
        >
          <div @click="openLink(post.message.redirect)" v-if="post.message.redirect && post.message.redirect != '/app/posts'" class="position-absolute top-0 end-0 py-1 px-3">
            <font-awesome-icon :icon="['far', 'external-link']"/>
          </div>
          <div v-if="post.unread" class="new-label"></div>
          <img src="@/assets/icon/icon.png" style="width:80px; height:80px;padding: 10px 0 0 10px">
          <!--div class="news-icon ms-2 mt-2"
               :style="{'background-image':  getIcon }" :class="{'news-icon-background': !icon}">
          </div-->
          <div>
            <ion-card-header style="position:relative;">

              <ion-card-subtitle>{{ moment(post.publish_at).format('dddd, MMMM D HH:mm') }}</ion-card-subtitle>
              <ion-card-title style="font-size:20px !important;">{{ post.message.title }}</ion-card-title>
            </ion-card-header>
            <ion-card-content v-html="post.content" style="font-size:1rem !important">
            </ion-card-content>
          </div>
        </div>
      </div>
      <div class="item item-text-wrap" style="padding: 20px!important; text-align: center;"
           v-if="!posts || posts.length <= 0"><span>{{ wordings[currentLanguage].NO_NEWS }}</span>
      </div>
    </ion-content>
  </base-layout>
</template>
<script>
import {
  IonContent,
  IonItem,
  IonRefresher,
  IonRefresherContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonSpinner
} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapMutations, mapState} from "vuex";
import moment from 'moment';
import {addIcons} from "ionicons";
import {chevronDownOutline, chevronUpOutline} from "ionicons/icons";
import router from '@/router';


addIcons({
  "chevron-down-outline": chevronDownOutline,
  "chevron-up-outline": chevronUpOutline,
});

export default defineComponent({
  name: "News",
  data() {
    return {
      collapsed: null
    };
  },
  components: {
    IonContent,
    IonItem,
    IonRefresher,
    IonRefresherContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonSpinner,
  },

  computed: {
    ...mapState('news', ['posts', 'fetchActive']),
    ...mapState('wordings', ['wordings', 'currentLanguage']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion','icon']),


  },
  methods: {
    ...mapActions("news", ["fetchNews"]),
    ...mapActions("personalDB", ['getItem', 'setTime', 'getUnreadNews']),
    ...mapMutations("personalDB", ['setUnreadNews']),
    async updateNews(event) {
      let news = await this.fetchNews(this.currentConference);
      console.log('news', news)
      event.target.complete();
    },
    collapse(post) {
      if (post == this.collapsed) {
        this.collapsed = null;
      } else {
        this.collapsed = post;
      }
    },

    collapseStyle(post) {
      if (post == this.collapsed) {
        return {
          'max-height': 'none',
          'min-height': '180px !important',
        };
      } else {
        return {
          'max-height': '140px !important',
          'min-height': '140px !important',
        };
      }
    },
    openLink(link) {
      router.push(link)
    },
    getIcon() {
      return this.icon ? 'url( ' + this.icon + ')' : 'url("@/assets/icon/icon.png")';
    }
  },

  async created() {
    this.moment = moment;
    //await this.fetchNews(config.conference);
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(route) {
        if (route.name == 'Newsboard') {
          this.moment = moment;
          await this.fetchNews(this.currentConference);
          await this.setTime({name: 'timeStamps_' + this.currentConference, item: new Date()});
          this.setUnreadNews(0);
        }
      }
    }
  }
});
</script>
<style lang="scss" scoped>
a {
  text-decoration: none
}

.new-label {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: green;
  display: block;
  width: 10px;
  min-height: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  z-index: 10;
  box-shadow: 0 0 0 rgba(0, 128, 0, 0.4);
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 128, 0, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 128, 0, 0.4);
    box-shadow: 0 0 0 0 rgba(0, 128, 0, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse2 {
  0% {
    -moz-box-shadow: 0 0 0 rgba(240, 0, 0, 1);
    box-shadow: 0 0 0 rgba(240, 0, 0, 1);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.news-icon {
  width: fit-content;
  text-align: center;
  width: 80px;
  height: 80px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}


</style>
